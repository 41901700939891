import React, { PureComponent } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";

const CURRENT_USER_QUERY = gql`
  query {
    me {
      id
      email
      firstName
      lastName
      companyName
      shortDescription
      longDescription
      capability{
        id
        itemId
        label
      }
      screenShot
      vendorUrl
      role
      profilePic
      location
      locationCountry
      locationLat
      locationLng
      lastEdited
      hasReadRebrandMessage
      hasOwnStartup
      knowledgePoints
      stripeCustomerId
      ownStartupUrl
      nextAvailableFreeConvo
      whyMakeGoodCofounder
      numberOfFreeConvos
      lookingToJoinStartupAsFounder
      hasIdeaForStartup
      elevatorPitchForStartup
      interestedInJobsForStartups
      hasAdminRights
      optedInForMarketing
      email_subscribedToMessages
      email_subscribedToCommunityAnswers
      email_subscribedToInternalComms
      hasValidCoFounderSubscription
      startupIndustry {
        id
        itemId
        label
      }
      startupStage {
        id
        itemId
        label
      }
      hoursPerWeekAvailable {
        id
        itemId
        label
      }
      ideaStage {
        id
        itemId
        label
      }
      ideaIndustry {
        id
        itemId
        label
      }
      hasSkills {
        id
        itemId
        label
      }
      wantsSkills {
        id
        itemId
        label
      }
    }
  }
`;

const Me = (props) => (
  <Query query={CURRENT_USER_QUERY} ssr={false}>
    {(payload) => props.children(payload)}
  </Query>
);

export default Me;
export { CURRENT_USER_QUERY };
